import { SVGProps, Ref, forwardRef } from 'react'

const SvgFlash = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width="1.5rem"
    height="1.5rem"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <g clipPath="url(#Flash_svg__a)">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M17.848 11.306a1.022 1.022 0 0 0-.871-1.559H13.5v-9L6.152 12.689a1.022 1.022 0 0 0 .87 1.558H10.5v9l7.348-11.941Z"
      />
    </g>
    <defs>
      <clipPath id="Flash_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
)

const ForwardRef = forwardRef(SvgFlash)
export default ForwardRef
